import { default as indexGP7TWBF1pdMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/about-us/careers/index.vue?macro=true";
import { default as index4fl578wjnRMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/about-us/index.vue?macro=true";
import { default as brisbaneWEWsklW85kMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/brisbane.vue?macro=true";
import { default as indexIz9Jt1woOfMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/car-subscription/index.vue?macro=true";
import { default as thank_45youX9m58d8uBeMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/car-subscription/thank-you.vue?macro=true";
import { default as indexqECUZ4IkadMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/fleet-subscription/index.vue?macro=true";
import { default as index0zM23ZneofMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/fleet-subscription/small-business/index.vue?macro=true";
import { default as indexKFEL8ZrwcKMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/index.vue?macro=true";
import { default as indexEdOcG3J0sAMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/novated-ev-subscription/index.vue?macro=true";
import { default as indexHVYO4kny4EMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/novated-lease/estimate/index.vue?macro=true";
import { default as thank_45youjUTBmZBAmSMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/novated-lease/estimate/thank-you.vue?macro=true";
import { default as index4MJKE3u0WJMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/novated-lease/index.vue?macro=true";
import { default as indexHAIRoVQUa7Meta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/novated-subscription/index.vue?macro=true";
import { default as indexWgSjP8RCT1Meta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/tools-of-trade/employee-benefits/index.vue?macro=true";
import { default as indexrm2B9SIyjJMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/buying-a-car/buying-a-new-car/index.vue?macro=true";
import { default as indexRZm2DuuTXiMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/car-finance/index.vue?macro=true";
import { default as indexpiYub5vJ0AMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/car-subscription/fees-and-charges/index.vue?macro=true";
import { default as indexxg1fYnD7yCMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/car-subscription/index.vue?macro=true";
import { default as indexnhhWhz5XafMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/car-subscription/loyalty/index.vue?macro=true";
import { default as _91_46_46_46slug_93EPVasKhU3lMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/cars/subscribe/[...slug].vue?macro=true";
import { default as _91year_93_45_91body_type_93_45_91webref_code_93_45_91stock_id_93L4EwRoTI0DMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/cars/subscribe/[make]/[model]/[year]-[body_type]-[webref_code]-[stock_id].vue?macro=true";
import { default as indexp6MoIYq5M2Meta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/compare-car-subscriptions-vs-leasing/index.vue?macro=true";
import { default as indexscchEbrydQMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/dealers/index.vue?macro=true";
import { default as thank_45youHxkYqa6xglMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/dealers/thank-you.vue?macro=true";
import { default as indexe4gn7VBXt9Meta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/electric-vehicles/index.vue?macro=true";
import { default as _91_46_46_46slug_93GBZi4wuWj7Meta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/email-validation/[...slug].vue?macro=true";
import { default as indexQrwjjkCeT5Meta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/forgot-password/index.vue?macro=true";
import { default as indexqxw0ohRCueMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/index.vue?macro=true";
import { default as melbourneEq5gCyAkx7Meta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/melbourne.vue?macro=true";
import { default as indexmB9CdSO9gTMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/partners/greener-for-business/index.vue?macro=true";
import { default as indexI1hpGYBRQwMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/partners/lightfm/index.vue?macro=true";
import { default as thank_45youAM7BvbNMffMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/partners/thank-you.vue?macro=true";
import { default as indexRZjxwBdR5nMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/press/index.vue?macro=true";
import { default as indexRaIvCQSpQtMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/privacy-policy/index.vue?macro=true";
import { default as indexzX9gdDscOPMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/promotion/auroraenergy/get-electrified/index.vue?macro=true";
import { default as _91_46_46_46slug_933VdNgFGtndMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/reset-password/[...slug].vue?macro=true";
import { default as indexWihNxsJGnOMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/secure/login/index.vue?macro=true";
import { default as indexm7H5kB8SyjMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/sell-my-car/index.vue?macro=true";
import { default as thank_45youU6GIatpqddMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/sell-my-car/thank-you.vue?macro=true";
import { default as indexR708PehEtuMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/sign-up/index.vue?macro=true";
import { default as indexUszKrUOedYMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/sitemap/index.vue?macro=true";
import { default as sydneyziDjUfxqy4Meta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/sydney.vue?macro=true";
import { default as indexrUVP0QaXBiMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/terms-and-conditions/car-subscription/index.vue?macro=true";
import { default as indexnSBex1jPhzMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/terms-and-conditions/carbar-concierge/index.vue?macro=true";
import { default as indextxzxOt1wbDMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/terms-and-conditions/index.vue?macro=true";
import { default as indexGJZaBEap2uMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/terms-and-conditions/instant-valuation/index.vue?macro=true";
import { default as index1wAbsfqcIdMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/terms-and-conditions/purchase/index.vue?macro=true";
import { default as component_45stubR314pOzUzTMeta } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubR314pOzUzT } from "/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-us-careers",
    path: "/about-us/careers",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/about-us/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "brisbane",
    path: "/brisbane",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/brisbane.vue").then(m => m.default || m)
  },
  {
    name: "business-car-subscription",
    path: "/business/car-subscription",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/car-subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "business-car-subscription-thank-you",
    path: "/business/car-subscription/thank-you",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/car-subscription/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "business-fleet-subscription",
    path: "/business/fleet-subscription",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/fleet-subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "business-fleet-subscription-small-business",
    path: "/business/fleet-subscription/small-business",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/fleet-subscription/small-business/index.vue").then(m => m.default || m)
  },
  {
    name: "business",
    path: "/business",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/index.vue").then(m => m.default || m)
  },
  {
    name: "business-novated-ev-subscription",
    path: "/business/novated-ev-subscription",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/novated-ev-subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "business-novated-lease-estimate",
    path: "/business/novated-lease/estimate",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/novated-lease/estimate/index.vue").then(m => m.default || m)
  },
  {
    name: "business-novated-lease-estimate-thank-you",
    path: "/business/novated-lease/estimate/thank-you",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/novated-lease/estimate/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "business-novated-lease",
    path: "/business/novated-lease",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/novated-lease/index.vue").then(m => m.default || m)
  },
  {
    name: "business-novated-subscription",
    path: "/business/novated-subscription",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/novated-subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "business-tools-of-trade-employee-benefits",
    path: "/business/tools-of-trade/employee-benefits",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/business/tools-of-trade/employee-benefits/index.vue").then(m => m.default || m)
  },
  {
    name: "buying-a-car-buying-a-new-car",
    path: "/buying-a-car/buying-a-new-car",
    meta: indexrm2B9SIyjJMeta || {},
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/buying-a-car/buying-a-new-car/index.vue").then(m => m.default || m)
  },
  {
    name: "car-finance",
    path: "/car-finance",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/car-finance/index.vue").then(m => m.default || m)
  },
  {
    name: "car-subscription-fees-and-charges",
    path: "/car-subscription/fees-and-charges",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/car-subscription/fees-and-charges/index.vue").then(m => m.default || m)
  },
  {
    name: "car-subscription",
    path: "/car-subscription",
    meta: indexxg1fYnD7yCMeta || {},
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/car-subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "car-subscription-loyalty",
    path: "/car-subscription/loyalty",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/car-subscription/loyalty/index.vue").then(m => m.default || m)
  },
  {
    name: "cars-subscribe-slug",
    path: "/cars/subscribe/:slug(.*)*",
    meta: _91_46_46_46slug_93EPVasKhU3lMeta || {},
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/cars/subscribe/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cars-subscribe-make-model-year-body_type-webref_code-stock_id",
    path: "/cars/subscribe/:make()/:model()/:year()-:body_type()-:webref_code()-:stock_id()",
    meta: _91year_93_45_91body_type_93_45_91webref_code_93_45_91stock_id_93L4EwRoTI0DMeta || {},
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/cars/subscribe/[make]/[model]/[year]-[body_type]-[webref_code]-[stock_id].vue").then(m => m.default || m)
  },
  {
    name: "compare-car-subscriptions-vs-leasing",
    path: "/compare-car-subscriptions-vs-leasing",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/compare-car-subscriptions-vs-leasing/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers",
    path: "/dealers",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: "dealers-thank-you",
    path: "/dealers/thank-you",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/dealers/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "electric-vehicles",
    path: "/electric-vehicles",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/electric-vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: "email-validation-slug",
    path: "/email-validation/:slug(.*)*",
    meta: _91_46_46_46slug_93GBZi4wuWj7Meta || {},
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/email-validation/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexqxw0ohRCueMeta || {},
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "melbourne",
    path: "/melbourne",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/melbourne.vue").then(m => m.default || m)
  },
  {
    name: "partners-greener-for-business",
    path: "/partners/greener-for-business",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/partners/greener-for-business/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-lightfm",
    path: "/partners/lightfm",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/partners/lightfm/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-thank-you",
    path: "/partners/thank-you",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/partners/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "press",
    path: "/press",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "promotion-auroraenergy-get-electrified",
    path: "/promotion/auroraenergy/get-electrified",
    meta: indexzX9gdDscOPMeta || {},
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/promotion/auroraenergy/get-electrified/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-slug",
    path: "/reset-password/:slug(.*)*",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/reset-password/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "secure-login",
    path: "/secure/login",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/secure/login/index.vue").then(m => m.default || m)
  },
  {
    name: "sell-my-car",
    path: "/sell-my-car",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/sell-my-car/index.vue").then(m => m.default || m)
  },
  {
    name: "sell-my-car-thank-you",
    path: "/sell-my-car/thank-you",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/sell-my-car/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "sydney",
    path: "/sydney",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/sydney.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions-car-subscription",
    path: "/terms-and-conditions/car-subscription",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/terms-and-conditions/car-subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions-carbar-concierge",
    path: "/terms-and-conditions/carbar-concierge",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/terms-and-conditions/carbar-concierge/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions-instant-valuation",
    path: "/terms-and-conditions/instant-valuation",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/terms-and-conditions/instant-valuation/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions-purchase",
    path: "/terms-and-conditions/purchase",
    component: () => import("/codebuild/output/src3362950303/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/pages/terms-and-conditions/purchase/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business-car-subscription",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business-car-subscription/small",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business-car-subscription/small-business",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business-car-subscription/tools-of-trade",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business-car-subscription/corporate",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business/employee-benefits/salary-sacrifice",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business/employee-benefits/novated-ev-subscription",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business/employee-benefits/terms-and-conditions/car-subscription",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business/fleet-vehicle-subscription",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business/novated",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business/tools-of-trade",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business/small-business",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/business/fleet/vehicle-subscription",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/privacy",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/car-subscription-electric",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/subscription-vs-leasing",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/cars/subscribe/nt",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/cars/subscribe/sa",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/cars/subscribe/qld",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/partners",
    component: component_45stubR314pOzUzT
  },
  {
    name: component_45stubR314pOzUzTMeta?.name,
    path: "/cars/buy/used/:pathMatch(.*)",
    component: component_45stubR314pOzUzT
  }
]